import dayjs from "dayjs";
import React from "react";
import { Box } from "ticeboxes";
import { P } from "time-tracker-app-lib";

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <footer>
      <Box text-center my="5">
        <P grey>© {dayjs().year()} Time Tracker App</P>
      </Box>
    </footer>
  );
};

export default Footer;
